export const getOrdersState = (state) => state.orders;

export const getAllOrderDetails = (state) => getOrdersState(state).details;

export const getOrderDetailsFromOrderReference = (state) => (orderReference) =>
  getAllOrderDetails(state).find(
    (order) => order.orderReference === orderReference
  );

export const getOrderDeliveryCountryFromOrderReference =
  (state) => (orderReference) => {
    const order = getOrderDetailsFromOrderReference(state)(orderReference);
    if (order && order.deliveryAddress) {
      return order.deliveryAddress.countryCode;
    }
    return null;
  };

export const getDeliveryAddressForOrderReference =
  (state) => (orderReference) => {
    const order = getOrderDetailsFromOrderReference(state)(orderReference);
    if (order && order.deliveryAddress) {
      return order.deliveryAddress;
    }
    return null;
  };

export const getCancelOrderReference = (state) =>
  getOrdersState(state).cancelOrderReference;

export const getIfOrderIsBeingCancelled = (state) => (orderReference) =>
  getCancelOrderReference(state) === orderReference;

export const getTotalOrders = (state) => getOrdersState(state).total;

export const getPaymentsFromOrderReference = (state) => (orderReference) => {
  const order = getOrderDetailsFromOrderReference(state)(orderReference);
  return order && order.payment && order.payment.intents
    ? order.payment.intents
    : [];
};
