import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import PropTypes from "prop-types";

import appConfig from "@appConfig";

import Loading, { LoadingContainer } from "@components/loading";
import Icon from "@components/picture-icon";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { getPremierStatus as getPremierStatusAction } from "@state/premier/actions";

import { noticeError } from "@utils/monitoring";
import { redirectToUrl, addFragmentToUrl, validateUrl } from "@utils/url";

import {
  getKeyStoreDataversion,
  getPageCountry,
  getPageECommerceStore,
  getPageLanguage,
} from "../../../document-handlers";

import styles from "./index.css";

const ActivatingPremier = ({ silentTokenRefresh }) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const formatTranslation = useFormatTranslation();
  const INITIAL_WAIT_TIME = 4000;
  const TOTAL_WAIT_TIME = 30000;

  const createSuccessUri = () => {
    const guid = searchParams.get("guid");
    const urlParam = searchParams.get("redirectUrl");
    const redirectUrl = validateUrl(urlParam) ? urlParam : "";

    const keyStoreDataversion = getKeyStoreDataversion();
    const language = getPageLanguage();
    const store = getPageECommerceStore();
    const country = getPageCountry();

    const successUri = `${
      appConfig.clearTokensRedirectUri
    }?guid=${guid}&lang=${language}&store=${store}&countryCode=${country}&keyStoreDataversion=${keyStoreDataversion}${
      redirectUrl ? `&redirectUrl=${redirectUrl}&returnUrl=${redirectUrl}` : ""
    }`;

    const returnUri = redirectUrl
      ? encodeURIComponent(
          addFragmentToUrl(redirectUrl, "premier-activationerror")
        )
      : "";

    return { successUri, returnUri };
  };

  const dispatchPremierSubscriptionStatus = () => {
    return dispatch(getPremierStatusAction());
  };

  const delay = (backOff) =>
    new Promise((resolve) => setTimeout(resolve, backOff));

  const pollWithRetry = async (backOff, successUri, returnUri, waitTime) => {
    const response = await dispatchPremierSubscriptionStatus();
    if (response?.payload?.isPremierActive) {
      try {
        await silentTokenRefresh();
      } catch (e) {
        noticeError(e);
      }
      redirectToUrl(successUri);
      return;
    }

    waitTime += backOff;
    if (waitTime >= TOTAL_WAIT_TIME) {
      redirectToUrl(
        `${window.ASOS.myAccount.entryPoint}premier-activation-error${
          returnUri ? `?returnUrl=${returnUri}` : ""
        }`
      );
      return;
    }

    backOff += 4000;
    await delay(backOff);
    return pollWithRetry(backOff, successUri, returnUri, waitTime);
  };

  const pollSubscriptionApi = (successUri, returnUri) => {
    pollWithRetry(0, successUri, returnUri, INITIAL_WAIT_TIME);
  };

  useEffect(() => {
    const { successUri, returnUri } = createSuccessUri();
    const timeoutId = setTimeout(() => {
      pollSubscriptionApi(successUri, returnUri);
    }, INITIAL_WAIT_TIME);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <LoadingContainer fullScreen>
      <Loading />
      <div className={styles.premierIconWrapper}>
        <Icon id="colouredPremier" />
        <div
          aria-label={formatTranslation(
            "joinasospremier_loadingyourpremieraccountmessage"
          )}
          className={styles.loadingMessage}
        >
          <h1>
            {formatTranslation(
              "joinasospremier_loadingyourpremieraccountmessage"
            )}
          </h1>
          <p>
            {formatTranslation(
              "joinasospremier_loadingyourpremieraccountmessage_subtext"
            )}
          </p>
        </div>
      </div>
    </LoadingContainer>
  );
};

ActivatingPremier.propTypes = {
  silentTokenRefresh: PropTypes.func.isRequired,
};

export default ActivatingPremier;
