import { createAction } from "redux-actions";

import { sdk as audienceSDK } from "@asosteam/asos-web-audience-sdk";

import { REQUEST_AUDIENCE_SEGMENTS, RECEIVE_AUDIENCE_SEGMENTS } from "./types";

export const requestAudienceSegments = createAction(REQUEST_AUDIENCE_SEGMENTS);
export const receiveAudienceSegments = createAction(RECEIVE_AUDIENCE_SEGMENTS);

export const getAudienceSegments =
  () => async (dispatch, getState, identity) => {
    dispatch(requestAudienceSegments());

    try {
      const initialisedSDK = audienceSDK({
        getAccessToken: identity.getAccessToken,
        getCustomerGuid: identity.customer.customerGuid,
      });

      // const segments = [{ segmentId: "41e60798-97b8-421b-979e-7de84a4e3b70" }];
      const segments = await initialisedSDK.getSegments();

      return dispatch(receiveAudienceSegments(segments));
    } catch (err) {
      return dispatch(receiveAudienceSegments(err));
    }
  };
