import React, { useEffect } from "react";

import PropTypes from "prop-types";

import { Card, CardSection } from "@asosteam/asos-web-component-library-cards";
import { Heading } from "@asosteam/asos-web-component-library-heading";
import { Paragraph } from "@asosteam/asos-web-component-library-paragraph";
import { LONDON_2 } from "@asosteam/asos-web-component-library-typography";

import Layout from "@components/layout";
import PageHeader from "@components/page-header";
import Icon from "@components/picture-icon";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { Button } from "../../../components/button";

import styles from "./component.css";

const AddBillingAddress = ({ hasSavedPaymentMethods }) => {
  const formatTranslation = useFormatTranslation();
  useEffect(() => {
    const pageTitle = document.getElementById("page-title");
    pageTitle?.focus();
  }, []);

  return (
    <Layout data-auto-id="add-billing-address" showErrorSection={false}>
      <PageHeader
        title="ma_web_addpaymentmethod_title"
        previousPageLink={hasSavedPaymentMethods ? "/payment-methods" : "/"}
        type="primary"
        icon="payments"
        detached
      />
      <Card>
        <CardSection>
          <div className={styles.content}>
            <div className={styles.iconContainer}>
              <Icon id="addresses" />
            </div>

            <Heading sizing={LONDON_2} domElement="h3" isUppercase={true}>
              {formatTranslation("ma_web_paymentmethods_missingaddress")}
            </Heading>

            <div className={styles.paragraphContainer}>
              <Paragraph>
                {formatTranslation(
                  "ma_web_paymentmethods_missingaddressdescription"
                )}
              </Paragraph>
            </div>

            <Button to="/addresses/add">
              {formatTranslation("ma_web_addressbook_addnew")}
            </Button>
          </div>
        </CardSection>
      </Card>
    </Layout>
  );
};

AddBillingAddress.propTypes = {
  hasSavedPaymentMethods: PropTypes.bool.isRequired,
};

export default AddBillingAddress;
