import { createAction } from "redux-actions";

import { clearReturnAttempts } from "@state/returns/actions";

import { scrollErrorIntoView } from "@utils/scroll-into-view";

import { requestNotification } from "../application/actions";
import {
  getECommerceStore,
  getLanguage,
  getCountry as getBrowseCountry,
} from "../application/selectors";
import {
  getCustomerOrderDetails,
  getCustomerOrders,
  getMoreCustomerOrders,
  getOrderCancelReasons,
  getPaymentDetails as fetchPaymentDetails,
  postCancelOrder,
} from "./api";
import { getPaymentsFromOrderReference } from "./selectors";
import * as types from "./types";

export const apiError = createAction(types.API_ERROR);

export const requestOrders = createAction(
  types.REQUEST_ORDERS,
  ({ loadMore = false } = {}) => ({ loadMore })
);
export const receiveOrders = createAction(
  types.RECEIVE_ORDERS,
  (response, loadMore = false) => ({ loadMore, ...response })
);

export const getOrders =
  (getCustomerOrdersFn = getCustomerOrders) =>
  async (dispatch, getState, identity) => {
    const state = getState();
    const {
      orders: { loaded },
    } = state;

    if (loaded) {
      return null;
    }

    const store = getECommerceStore(state);
    const lang = getLanguage(state);
    const country = getBrowseCountry(state);

    dispatch(requestOrders());

    try {
      const response = await getCustomerOrdersFn(
        store,
        lang,
        identity,
        country
      );
      return dispatch(receiveOrders(response));
    } catch (e) {
      return dispatch(receiveOrders(e));
    }
  };

export const getMoreOrders =
  (getMoreCustomerOrdersFn = getMoreCustomerOrders) =>
  async (dispatch, getState, identity) => {
    dispatch(requestOrders({ loadMore: true }));

    const state = getState();
    const {
      orders: { all: allOrders },
    } = state;
    const offset = allOrders.length;

    const store = getECommerceStore(state);
    const lang = getLanguage(state);
    const country = getBrowseCountry(state);

    try {
      const response = await getMoreCustomerOrdersFn(
        offset,
        store,
        lang,
        identity,
        country
      );
      return dispatch(receiveOrders(response, true));
    } catch (err) {
      err.userAction = "getMoreOrders";
      return dispatch(apiError(err));
    }
  };

export const receiveOrderDetails = createAction(types.RECEIVE_ORDER_DETAILS);
export const requestOrderDetails = createAction(types.REQUEST_ORDER_DETAILS);

export const getOrderDetails =
  (
    orderReference,
    showLoadingSpinner = true,
    getCustomerOrderDetailsFn = getCustomerOrderDetails
  ) =>
  async (dispatch, getState, identity) => {
    if (showLoadingSpinner) {
      dispatch(requestOrderDetails());
    }

    const state = getState();
    const store = getECommerceStore(state);
    const lang = getLanguage(state);
    const country = getBrowseCountry(state);

    try {
      const response = await getCustomerOrderDetailsFn(
        orderReference,
        store,
        lang,
        identity,
        country
      );
      return dispatch(receiveOrderDetails(response));
    } catch (err) {
      err.orderReference = orderReference;
      return dispatch(receiveOrderDetails(err));
    }
  };

export const clearOrderCancelReasonsAttempts = createAction(
  types.CLEAR_ORDER_CANCEL_REASONS_ATTEMPTS
);
export const receiveOrderCancelReasons = createAction(
  types.RECEIVE_ORDER_CANCEL_REASONS
);
export const requestOrderCancelReasons = createAction(
  types.REQUEST_ORDER_CANCEL_REASONS
);

export const getCancelReasons =
  (orderReference, getCancelReasonsFn = getOrderCancelReasons) =>
  async (dispatch, getState, identity) => {
    const {
      application: { language },
      orders: { cancelReasons },
    } = getState();
    dispatch(clearReturnAttempts());
    dispatch(requestOrderCancelReasons({ orderReference }));
    try {
      if (cancelReasons.length > 0) {
        return dispatch(receiveOrderCancelReasons(cancelReasons));
      }
      const response = await getCancelReasonsFn(language, identity);
      return dispatch(receiveOrderCancelReasons(response));
    } catch (err) {
      err.userAction = "getCancelReasons";
      dispatch(apiError(err));
      return scrollErrorIntoView();
    }
  };

export const clearCancelOrderAction = createAction(types.CLEAR_CANCEL_ORDER);
export const clearCancelOrder = () => (dispatch) =>
  dispatch(clearCancelOrderAction());

export const requestCancelOrder = createAction(types.REQUEST_CANCEL_ORDER);
export const receiveCancelOrder = createAction(types.RECEIVE_CANCEL_ORDER);

const apiValidationError = (error) => error.apiValidationError;

export const orderSentForFulfilment = (error) => {
  if (error.body) {
    const errorList = error.body;
    return !!errorList.find(
      ({ errorCode }) => errorCode === "OrderSentForFulfilment"
    );
  }
  return false;
};

export const cancelOrder =
  (payload, cancelOrderFn = postCancelOrder) =>
  async (dispatch, getState, identity) => {
    dispatch(requestCancelOrder());
    try {
      const response = await cancelOrderFn(payload, identity);
      dispatch(
        requestNotification("success", "ma_web_order_cancellationrequested")
      );
      return dispatch(receiveCancelOrder({ ...payload, ...response }));
    } catch (err) {
      err.orderReference = payload.orderReference;

      if (apiValidationError(err) || orderSentForFulfilment(err)) {
        err.outOfTimeError = err.statusCode === 409;
        return dispatch(receiveCancelOrder(err));
      }

      err.userAction = "cancelOrder";
      return dispatch(apiError(err));
    }
  };

export const openTrackHelp = createAction(types.OPEN_TRACK_HELP);

export const trackOpenHelpLink = (payload) => (dispatch) => {
  dispatch(openTrackHelp({ orderReference: payload.orderReference }));
};

export const openTrackParcel = createAction(types.OPEN_TRACK_PARCEL);

export const openTrackParcelUrl =
  (payload, pageWindow = window) =>
  (dispatch) => {
    dispatch(
      openTrackParcel({
        isSingleParcel: payload.isSingleParcel,
        orderReference: payload.orderReference,
        parcelNo: payload.parcelNo,
      })
    );
    pageWindow.open(payload.url);
  };

export const productDetails = createAction(types.PRODUCT_DETAILS);

export const clickProductDetails = (payload) => (dispatch) => {
  dispatch(productDetails(payload));
};

export const requestPaymentDetails = createAction(
  types.REQUEST_PAYMENT_DETAILS
);
export const receivePaymentDetails = createAction(
  types.RECEIVE_PAYMENT_DETAILS
);

export const getPaymentDetails =
  (
    orderReference,
    paymentReference,
    getPaymentDetailsFn = fetchPaymentDetails
  ) =>
  async (dispatch, getState, identity) => {
    try {
      const state = getState();
      if (getPaymentsFromOrderReference(state)(orderReference).length > 0) {
        return;
      }

      dispatch(requestPaymentDetails());

      const paymentDetails = await getPaymentDetailsFn(
        paymentReference,
        getLanguage(state),
        identity
      );

      dispatch(
        receivePaymentDetails({
          orderReference,
          paymentReference,
          paymentDetails,
        })
      );
    } catch (err) {
      dispatch(receivePaymentDetails(err));
    }
  };
