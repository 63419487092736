import { handleActions } from "redux-actions";

import { replaceInArray, updateObjectInArray } from "@utils/array";

import { CLEAR_API_ERRORS } from "../application/types";
import { getOrderDetailsFromOrderReference } from "./selectors";
import {
  API_ERROR,
  CLEAR_CANCEL_ORDER,
  CLEAR_ORDER_CANCEL_REASONS_ATTEMPTS,
  RECEIVE_CANCEL_ORDER,
  RECEIVE_ORDER_CANCEL_REASONS,
  RECEIVE_ORDER_DETAILS,
  RECEIVE_ORDERS,
  RECEIVE_PAYMENT_DETAILS,
  REQUEST_CANCEL_ORDER,
  REQUEST_ORDER_CANCEL_REASONS,
  REQUEST_ORDER_DETAILS,
  REQUEST_ORDERS,
} from "./types";

const normaliseTrackingUrls = (orderDetail) => ({
  ...orderDetail,
  trackingUrls: orderDetail.trackingUrls ? orderDetail.trackingUrls : [],
});

const reducer = handleActions(
  {
    [API_ERROR]: (state, { payload: { userAction } }) => {
      const { maxRetries, tries } = state;
      if (tries[userAction] >= maxRetries) {
        return {
          ...state,
          apiError: {},
          fatalError: true,
          [userAction === "cancelOrder" && "cancelLoading"]: false,
          [userAction === "getCancelReasons" && "cancelReasonsLoading"]: false,
        };
      }
      return {
        ...state,
        apiError: {
          [userAction]: true,
        },
        fatalError: false,
        loadedPagination: true,
        tries: {
          ...state.tries,
          [userAction]: (tries[userAction] || 0) + 1,
        },
        [userAction === "cancelOrder" && "cancelLoading"]: false,
        [userAction === "getCancelReasons" && "cancelReasonsLoading"]: false,
      };
    },
    [CLEAR_API_ERRORS]: (state) => ({
      ...state,
      fatalError: false,
      loadedPagination: true,
      apiError: {},
      tries: {},
    }),
    [CLEAR_CANCEL_ORDER]: (state) => ({
      ...state,
      cancelOrderReference: "",
    }),
    [REQUEST_CANCEL_ORDER]: (state) => ({
      ...state,
      cancelLoading: true,
    }),
    [RECEIVE_CANCEL_ORDER]: (state, { error, payload }) => {
      const isOrderCondition = (order) =>
        order.orderReference === state.cancelOrderReference;
      const setNotCancellable = (order) => ({
        ...order,
        isCancellable: false,
      });

      const setCancelRequestedStatus = (order) =>
        setNotCancellable({
          ...order,
          status: "orderCancellationRequested",
          orderStatus: {
            ...order.orderStatus,
            status: "orderCancellationRequested",
            statusText: "ma_web_order_cancellationrequested",
          },
          deliveryGroupsDetail: order.deliveryGroupsDetail.map((item) => ({
            ...item,
            deliveryGroupStatus: {
              ...item.deliveryGroupStatus,
              status: "orderCancellationRequested",
              statusText: "ma_web_order_cancellationrequested",
            },
          })),
        });

      if (error) {
        if (payload.outOfTimeError) {
          return {
            ...state,
            all: updateObjectInArray(
              state.all,
              isOrderCondition,
              setNotCancellable
            ),
            cancelFatalError: payload,
            cancelLoading: false,
            details: updateObjectInArray(
              state.details,
              isOrderCondition,
              setNotCancellable
            ),
          };
        }

        return {
          ...state,
          cancelLoading: false,
          cancelFatalError: payload,
        };
      }
      return {
        ...state,
        all: updateObjectInArray(
          state.all,
          isOrderCondition,
          setCancelRequestedStatus
        ),
        cancelFatalError: {},
        cancelLoading: false,
        cancelOrderReference: "",
        details: updateObjectInArray(
          state.details,
          isOrderCondition,
          setCancelRequestedStatus
        ),
      };
    },
    [REQUEST_ORDERS]: (state, { payload }) => ({
      ...state,
      fatalError: false,
      loaded: payload.loadMore ? state.loaded : false,
      loadedPagination: false,
    }),
    [RECEIVE_ORDERS]: (state, { error, payload }) => {
      if (error) {
        return {
          ...state,
          fatalError: true,
          loaded: false,
          loadedPagination: false,
        };
      }
      const { orderSummaries: items, itemCount: total } = payload;
      const newDetails = items.map(normaliseTrackingUrls);

      return {
        ...state,
        all: [...state.all, ...newDetails],
        fatalError: false,
        loaded: true,
        loadedPagination: true,
        total,
      };
    },
    [REQUEST_ORDER_CANCEL_REASONS]: (state, { payload }) => ({
      ...state,
      cancelOrderReference: payload.orderReference,
      cancelReasonsLoading: true,
    }),
    [RECEIVE_ORDER_CANCEL_REASONS]: (state, { error, payload }) => {
      if (error) {
        return {
          ...state,
          cancelReasonsFatalError: true,
          cancelReasonsLoading: false,
        };
      }
      return {
        ...state,
        cancelReasons: payload,
        cancelReasonsFatalError: false,
        cancelReasonsLoading: false,
      };
    },
    [CLEAR_ORDER_CANCEL_REASONS_ATTEMPTS]: (state) => ({
      ...state,
      apiError: {
        ...state.apiError,
        getCancelReasons: false,
      },
      tries: {
        ...state.tries,
        getCancelReasons: 0,
      },
      cancelOrderReference: "",
    }),
    [REQUEST_ORDER_DETAILS]: (state) => ({
      ...state,
      detailsFatalError: false,
      loadedDetails: false,
    }),
    [RECEIVE_ORDER_DETAILS]: (state, { error, payload }) => {
      const existingEntry = getOrderDetailsFromOrderReference({
        orders: state,
      })(payload.orderReference);

      if (error) {
        return {
          ...state,
          detailsFatalError: !existingEntry,
          loadedDetails: true,
        };
      }

      const isCancellable = existingEntry
        ? existingEntry.isCancellable && payload.isCancellable
        : payload.isCancellable;

      const orderDetail = {
        ...normaliseTrackingUrls(payload),
        payment: existingEntry ? existingEntry.payment : {},
        isCancellable,
      };

      if (existingEntry) {
        const isOrderCondition = ({ orderReference }) =>
          orderReference === payload.orderReference;
        return {
          ...state,
          details: replaceInArray(state.details, isOrderCondition, orderDetail),
          detailsFatalError: false,
          loadedDetails: true,
        };
      }

      return {
        ...state,
        details: [...state.details, orderDetail],
        detailsFatalError: false,
        loadedDetails: true,
      };
    },
    [RECEIVE_PAYMENT_DETAILS]: (state, { error, payload }) => {
      if (error) {
        return state;
      }

      const existingEntry = getOrderDetailsFromOrderReference({
        orders: state,
      })(payload.orderReference);
      if (!existingEntry) {
        return state;
      }

      const isOrderCondition = ({ orderReference }) =>
        orderReference === payload.orderReference;

      return {
        ...state,
        details: replaceInArray(state.details, isOrderCondition, {
          ...existingEntry,
          payment: payload.paymentDetails,
        }),
      };
    },
  },
  {
    all: [],
    apiError: {},
    cancelOrderReference: "",
    cancelFatalError: {},
    cancelLoading: false,
    cancelReasons: [],
    cancelReasonsFatalError: false,
    cancelReasonsLoading: false,
    details: [],
    detailsFatalError: false,
    loadedDetails: false,
    fatalError: false,
    loaded: false,
    loadedPagination: false,
    maxRetries: 2,
    total: null,
    tries: {},
  }
);

export default reducer;
