import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Sticky from "react-sticky-el";

import PropTypes from "prop-types";

import Footer from "@components/footer";
import { GridRow, GridColumn } from "@components/grid";
import Header from "@components/header";
import ContentWrapper from "@components/layout/content-wrapper";
import SkipToContent from "@components/skip-to-content";

import { mainId } from "@configuration/application";

import {
  appLoaded as applicationLoaded,
  getAppStartupError,
  getDigiCertSealCode,
  getIfChromeShouldBeShown,
  getLanguage,
} from "@state/application/selectors";

import AppContentSection from "./app-content-section";
import AppStartupErrorSection from "./app-startup-error-section";
import LoadingAppContentSection from "./loading-app-content-section";
import StickySection from "./sticky-section";

import styles from "./component.css";

const scrollToTopOfPage = () => {
  window.scroll({
    top: 0,
    behavior: "smooth",
  });
};

const Layout = ({
  children,
  contentFlex,
  "data-auto-id": dataAutoId,
  isHome,
  noMainContent,
  showErrorSection,
  showScrollToTopButton,
  showSmallNavigationOnly,
}) => {
  const {
    appLoaded,
    appStartupError,
    digiCertSealCode,
    language,
    notification,
    showChrome,
  } = useSelector((state) => ({
    appLoaded: applicationLoaded(state),
    appStartupError: getAppStartupError(state),
    digiCertSealCode: getDigiCertSealCode(state),
    language: getLanguage(state),
    showChrome: getIfChromeShouldBeShown(state),
  }));

  if (showErrorSection) {
    scrollToTopOfPage();
  }

  useEffect(() => {
    if (appLoaded) {
      scrollToTopOfPage();
    }
  }, [appLoaded]);

  return (
    <div className={styles.layout} data-auto-id={dataAutoId}>
      <div className={styles.inner}>
        <div className={styles.main}>
          {!noMainContent && <SkipToContent />}
          {showChrome && (
            <Header
              digiCertSealCode={digiCertSealCode}
              language={language}
              isHome={isHome}
            />
          )}
          {appStartupError ? (
            <AppStartupErrorSection />
          ) : (
            <div className={styles.contentContainer}>
              <AppContentSection
                appLoaded={appLoaded}
                content={children}
                contentFlex={contentFlex}
                mainId={mainId}
                mainStyle={styles.main}
                notification={notification}
                showChrome={showChrome}
                showErrorSection={showErrorSection}
                showSmallNavigationOnly={showSmallNavigationOnly}
              />
              <LoadingAppContentSection
                appLoaded={appLoaded}
                contentFlex={contentFlex}
                showChrome={showChrome}
                showSmallNavigationOnly={showSmallNavigationOnly}
              />
            </div>
          )}
          <ContentWrapper>
            <div className={styles.contentWrapperInner}>
              <div className={styles.notificationRowWrapper}>
                <GridRow>
                  {showChrome && (
                    <GridColumn smallSize={12} largeSize={4}>
                      &nbsp;
                    </GridColumn>
                  )}
                  <GridColumn smallSize={12} largeSize={showChrome ? 8 : 12}>
                    <Sticky
                      mode="bottom"
                      positionRecheckInterval={1000}
                      className={styles.sticky}
                    >
                      <StickySection showScrollToTop={showScrollToTopButton} />
                    </Sticky>
                  </GridColumn>
                </GridRow>
              </div>
            </div>
          </ContentWrapper>
        </div>
        {showChrome && (
          <div className={styles.footerWrapper}>
            <Footer />
          </div>
        )}
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  contentFlex: PropTypes.bool,
  "data-auto-id": PropTypes.string,
  isHome: PropTypes.bool,
  noMainContent: PropTypes.bool,
  showErrorSection: PropTypes.bool,
  showScrollToTopButton: PropTypes.bool,
  showSmallNavigationOnly: PropTypes.bool,
};

Layout.defaultProps = {
  children: undefined,
  contentFlex: false,
  "data-auto-id": null,
  isHome: false,
  noMainContent: false,
  showErrorSection: false,
  showScrollToTopButton: false,
  showSmallNavigationOnly: false,
};

export default Layout;
