import request from "asos.myaccount.request";

import appConfig from "@appConfig";

import { ordersPageSize } from "@configuration/order-history";

export const getCustomerOrders = (
  store,
  lang,
  identity,
  country,
  requestFn = request
) =>
  requestFn(
    `commerce/myaccount/${
      appConfig.orderDetailsV2Countries ? "v2" : "v1"
    }/customers/{customerId}/orders?limit=${ordersPageSize}&store=${store}&lang=${lang}`,
    identity
  );

export const getMoreCustomerOrders = (
  offset,
  store,
  lang,
  identity,
  country,
  requestFn = request
) =>
  requestFn(
    `commerce/myaccount/${
      appConfig.orderDetailsV2Countries ? "v2" : "v1"
    }/customers/{customerId}/orders`,
    identity,
    {
      query: {
        offset,
        limit: ordersPageSize,
        store: lang,
      },
    }
  );

export const getCustomerOrderDetails = (
  orderReference,
  store,
  lang,
  identity,
  country,
  requestFn = request
) =>
  requestFn(
    `commerce/myaccount/${
      appConfig.orderDetailsV2Countries ? "v2" : "v1"
    }/customers/{customerId}/orders/${orderReference}?store=${store}&lang=${lang}`,
    identity
  );

export const getOrderCancelReasons = (
  language,
  identity,
  requestFn = request
) =>
  requestFn(`commerce/order/v3/cancellationreasons?lang=${language}`, identity);

export const getPaymentDetails = (
  paymentReference,
  lang,
  identity,
  requestFn = request
) =>
  requestFn(
    `finance/payments-query/v1/payments/${paymentReference}`,
    identity,
    {
      query: { lang },
    }
  );

export const postCancelOrder = (
  { orderReference, reasonId, notes },
  identity,
  requestFn = request
) =>
  requestFn(
    `commerce/order/v3/orders/${orderReference}/cancelorder`,
    identity,
    {
      body: JSON.stringify({
        reasonId,
        notes,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    }
  );
