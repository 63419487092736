import cookie from "js-cookie";

const ASOS_COOKIE = "asos";

export const getDocumentAttribute = (attribute, root = document) => {
  const element = root.documentElement;
  if (element) {
    return element.getAttribute(attribute);
  }
  return null;
};

const getAsosCookieValue = (cookieKey) => {
  const asosCookie = cookie.get(ASOS_COOKIE);
  if (!asosCookie) {
    return null;
  }

  const cookieValues = new URLSearchParams(asosCookie);
  return cookieValues.get(cookieKey);
};

export const getKeyStoreDataversion = (pageDocument = document) =>
  getDocumentAttribute("data-key-store-data-version", pageDocument);

export const getApplicationVersion = (pageDocument = document) =>
  getDocumentAttribute("data-application-version", pageDocument);

export const getApiRoot = (pageDocument = document) =>
  getDocumentAttribute("data-api-root", pageDocument);

export const getApiResponseTimeout = (pageDocument = document) =>
  getDocumentAttribute("data-api-response-timeout", pageDocument);

export const getPageLanguage = (pageDocument = document) =>
  getDocumentAttribute("lang", pageDocument);

export const getPageECommerceStore = (pageDocument = document) =>
  getDocumentAttribute("data-store", pageDocument);

export const getPagePlatform = (pageDocument = document) =>
  getDocumentAttribute("data-platform", pageDocument);

export const getPageCountry = (pageDocument = document) =>
  getDocumentAttribute("data-country", pageDocument).toUpperCase();

export const getPageCurrency = (pageDocument = document) =>
  getDocumentAttribute("data-currency", pageDocument);

export const getPageSiteRoot = (pageDocument = document) =>
  `https://${getDocumentAttribute("data-site", pageDocument)}`;

// Attempt to get the customerGuid and customerId from the cookie, we will get it from identity later and refresh it
export const getCustomerGuid = () => getAsosCookieValue("customerguid");
export const getCustomerId = () => getAsosCookieValue("customerid");
