import { ordersPageSize } from "@configuration/order-history";

import {
  getCountry,
  getCurrency,
  getECommerceStore,
  getLanguage,
  getPlatform,
} from "@state/application/selectors";
import { getCustomerId } from "@state/identity/selectors";
import { hasPremierSubscription } from "@state/premier/selectors";

import {
  DELIVERY_TYPES,
  determineDeliveryType,
} from "@utils/orders/deliveryType";

import {
  AllOrderType,
  AnalyticsState,
  DeliveryGroupDetail,
} from "./pageLoadHook";

interface ActionType {
  meta: {
    form: string;
  };
}

interface OrderTotal {
  totalDiscount: {
    value: number;
  };
}

export enum trackingFrequency {
  ALWAYS = "always",
  SESSION = "session",
}

export const getBasicPayload = (state: AnalyticsState) => ({
  countryISO: getCountry(state),
  currency: getCurrency(state),
  customerID: getCustomerId(state),
  gender: state.user.gender === "M" ? "men" : "women",
  language: getLanguage(state).toLowerCase(),
  pageType: "account page",
  platform: getPlatform(state).toLowerCase(),
  premierUser: hasPremierSubscription(state),
  recognizedUser: state.identity.isRecognised,
  reconsentStatus: undefined,
  siteSection: "account",
  storeID: getECommerceStore(state).toLowerCase(),
  trackingFrequency: trackingFrequency.ALWAYS,
  url: document.location.href,
  visitPageCount: undefined,
});

export const getReconsentStatus = () => {
  return undefined;
};

export const getOrderFromOrderRef = (
  state: AnalyticsState,
  orderReference: string
): { status: string; deliveryGroupsDetail: Array<DeliveryGroupDetail> } =>
  state.orders.all.find((order) => order.orderReference === orderReference) ||
  state.orders.details.find((order) => order.orderReference === orderReference);

export const getOrderPaginationNumber = (state: AnalyticsState): number =>
  Math.ceil(state.orders.all.length / ordersPageSize);

export const getRetutningItemsCount = (items: object) =>
  Object.keys(items).reduce((acc, key) => {
    const item = items[key];
    if (!item.isReturning) {
      return acc;
    }
    return acc + item.quantity;
  }, 0);

export const getFromattedRetutningItems = (items: object) =>
  Object.keys(items).reduce((acc, key) => {
    const item = items[key];
    if (!item.isReturning) {
      return acc;
    }
    return [
      ...acc,
      {
        quantity: item.quantity,
        reasonId: item.reasonId,
        variantId: item.variantId,
      },
    ];
  }, []);

export const getOrderHasDiscount = (orderTotal: OrderTotal) =>
  !!(
    orderTotal &&
    orderTotal.totalDiscount &&
    orderTotal.totalDiscount.value !== 0
  );

export function getDeliveryTypeCounts(orders: Array<AllOrderType>) {
  const deliveryTypeCounts = orders.reduce(
    (counts, order) => {
      order.deliveryGroupsDetail.forEach((dg) => {
        const mappedItems = order.items?.filter(({ variantId }) =>
          dg.variants.some(
            (parcelVariant) => parcelVariant.variantId === variantId
          )
        );

        let isAfs = false;
        let isDtc = false;

        mappedItems?.forEach((item) => {
          const deliveryType = determineDeliveryType(item.seller, dg.source);
          if (
            deliveryType === DELIVERY_TYPES.AFS_NON_PRIME ||
            deliveryType === DELIVERY_TYPES.AFS_PRIME
          ) {
            isAfs = true;
          } else if (deliveryType === DELIVERY_TYPES.DTC) {
            isDtc = true;
          }
        });

        if (isAfs) {
          counts.afs += 1;
        } else if (isDtc) {
          counts.dtc += 1;
        }
      });

      return counts;
    },
    {
      afs: 0,
      dtc: 0,
    }
  );
  return deliveryTypeCounts;
}

/*
 * Helper method to figure out which Redux Form fields
 * has changed compared to the initial state.
 */
export const detectChangedFields = (
  action: ActionType,
  state: AnalyticsState
) => {
  switch (action.meta.form) {
    case "my-details":
      return Object.keys(state.form["my-details"].fields)
        .map((key) => ({
          [key]:
            state.form["my-details"].values[key] !==
            state.form["my-details"].initial[key],
        }))
        .filter((key) => key);
    default:
      return null;
  }
};
