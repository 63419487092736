import React from "react";
import { useLocation, matchPath } from "react-router";

import classnames from "classnames";
import PropTypes from "prop-types";

import NotificationBadge from "@components/notification-badge";
import Icon from "@components/picture-icon";
import { Translation } from "@components/translation";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { mainId } from "@configuration/application";

import { SmartLink } from "../../../smart-link";

import styles from "./index.css";

export const isActivePathMatch = (pathname, path) => {
  if (!pathname) {
    return false;
  }
  if (path !== "/") {
    return pathname.includes(path);
  } else {
    return pathname === path;
  }
};

const AlternativeAnchor = ({ children, className, onClick, onKeyDown, to }) => {
  const href = to ? `/my-account${to}` : undefined;
  const Element = to ? "a" : "span";
  return (
    <Element
      aria-current="page"
      className={className}
      href={href}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      {children}
    </Element>
  );
};

AlternativeAnchor.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  to: PropTypes.string,
};

AlternativeAnchor.defaultProps = {
  onClick: undefined,
  onKeyDown: undefined,
  to: undefined,
};

export const getAriaLabel = (
  textTranslated,
  tagTextTranslated,
  formatTranslation
) => {
  if (textTranslated && tagTextTranslated) {
    return `${formatTranslation(textTranslated)} (${formatTranslation(
      tagTextTranslated
    )})`;
  }

  return null;
};

export const NavLink = ({
  hasNotification,
  icon,
  iconOffset,
  tagText,
  text,
}) => {
  const formatTranslation = useFormatTranslation();

  return (
    <>
      <div className={styles.iconWrapper}>
        <div
          className={classnames({
            [styles.iconInner]: true,
            [styles.iconOffset]: iconOffset,
          })}
        >
          <Icon id={icon} size={icon === "colouredPremier" ? "small" : ""} />
          {hasNotification && (
            <div className={styles.badge}>
              <NotificationBadge />
            </div>
          )}
        </div>
      </div>
      <div
        aria-label={getAriaLabel(text, tagText, formatTranslation)}
        className={styles.text}
      >
        <Translation id={text} />
      </div>
      {tagText && (
        <div className={styles.tagWrapper}>
          <div aria-hidden className={styles.tag} data-auto-id="nav-tag">
            <Translation id={tagText} />
          </div>
        </div>
      )}
    </>
  );
};

NavLink.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  hasNotification: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  iconOffset: PropTypes.bool.isRequired,
  tagText: PropTypes.string,
  text: PropTypes.string.isRequired,
};

NavLink.defaultProps = {
  tagText: undefined,
};

const getLinkComponent = (path, exactMatch) => {
  if (path && !exactMatch) {
    return SmartLink;
  }
  return AlternativeAnchor;
};

const getInteractionHandler = (exactPathMatch, pageDocument) => {
  if (exactPathMatch) {
    return () => {
      const doc = pageDocument || window.document;
      const mainElement = doc.getElementById(mainId);
      mainElement?.focus();
      mainElement?.blur();
    };
  }
  return undefined;
};

const handleClick =
  (handler, preflightHandler, setMenuClicked, onClick) => (event) => {
    if (setMenuClicked) {
      setMenuClicked();
    }

    if (onClick) {
      onClick();
    }

    if (preflightHandler) {
      preflightHandler();
    }

    if (handler) {
      event.preventDefault();
      handler();
    }
  };

const handleKeyDown =
  (handler, preflightHandler, setMenuClicked, onClick) => (event) => {
    if (setMenuClicked) {
      setMenuClicked();
    }

    if (onClick) {
      onClick();
    }

    if (preflightHandler) {
      preflightHandler();
    }
    if (handler && (event.key === "Enter" || event.key === " ")) {
      event.preventDefault();
      return handler();
    }
    return undefined;
  };

const NavItem = ({
  disabled,
  forcePageReload,
  hasNotification,
  hideBorderTop,
  icon,
  pageDocument,
  path,
  preflightHandler,
  tagText,
  text,
  setMenuClicked,
  showBorderBottom,
  shownOnlyOnLarge,
  onClick,
}) => {
  const formatTranslation = useFormatTranslation();
  const { pathname } = useLocation();

  const exactPathMatch = matchPath(path, pathname);

  const LinkComponent = getLinkComponent(path, exactPathMatch);
  const interactionHandler = getInteractionHandler(
    exactPathMatch,
    pageDocument
  );

  return (
    <li
      className={classnames({
        [styles.item]: true,
        [styles.hideBorderTop]: hideBorderTop,
      })}
      disabled={disabled}
    >
      <span
        key={text}
        className={classnames({
          [styles.shownOnlyOnLarge]: shownOnlyOnLarge,
        })}
      >
        <LinkComponent
          className={classnames({
            [styles.link]: true,
            [styles.hasTag]: !!tagText,
            [styles.active]: isActivePathMatch(pathname, path) && !onClick,
            [styles.navItem]: showBorderBottom,
          })}
          onClick={handleClick(
            interactionHandler,
            preflightHandler,
            setMenuClicked,
            onClick
          )}
          onInternalClick={handleClick(
            interactionHandler,
            preflightHandler,
            setMenuClicked,
            onClick
          )}
          onKeyDown={handleKeyDown(
            interactionHandler,
            preflightHandler,
            setMenuClicked,
            onClick
          )}
          forcePageReload={forcePageReload}
          to={path}
        >
          <NavLink
            hasNotification={hasNotification}
            icon={icon}
            tagText={tagText}
            text={text}
            formatTranslation={formatTranslation}
            iconOffset={showBorderBottom}
          />
        </LinkComponent>
      </span>
    </li>
  );
};

NavItem.propTypes = {
  disabled: PropTypes.bool,
  hasNotification: PropTypes.bool,
  forcePageReload: PropTypes.bool,
  hideBorderTop: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  pageDocument: PropTypes.shape({
    getElementById: PropTypes.func.isRequired,
  }),
  path: PropTypes.string,
  preflightHandler: PropTypes.func,
  tagText: PropTypes.string,
  text: PropTypes.string.isRequired,
  setMenuClicked: PropTypes.func.isRequired,
  showBorderBottom: PropTypes.bool.isRequired,
  shownOnlyOnLarge: PropTypes.bool,
  onClick: PropTypes.func,
};

NavItem.defaultProps = {
  disabled: false,
  hasNotification: false,
  forcePageReload: false,
  pageDocument: undefined, // Use of window.document as a default value here kills half the test suite.
  path: "/",
  preflightHandler: null,
  tagText: null,
  shownOnlyOnLarge: false,
};

export default NavItem;
