import { addDays, differenceInCalendarDays, parseISO } from "date-fns";

import appConfig from "@appConfig";

import { applyUtc } from "@utils/date";
import { formatPrice } from "@utils/format-price";
import { urlContainsString } from "@utils/url";

const getUserState = (state) => state.user;
const getPremierState = (state) => state.premier;

export const getFatalError = (state) => getPremierState(state).fatalError;

export const isPremierAutoRenewalsEnabled = () =>
  !!appConfig.premierAutoRenewalsEnabled;

export const hasLoaded = (state) =>
  isPremierAutoRenewalsEnabled()
    ? getPremierState(state).loaded
    : getUserState(state).loaded;

export const getUserSubscriptions = (state) =>
  isPremierAutoRenewalsEnabled()
    ? getPremierState(state).subscriptions
    : getUserState(state).subscriptions;

export const isPremierSuccessUrl = () => urlContainsString("#signup-success");

export const getLatestUserSubscription = (state) => {
  const subscriptions = getUserSubscriptions(state);
  if (!subscriptions || subscriptions.length === 0) {
    return null;
  }
  const userSubscriptions = subscriptions.reduce(
    (prevSubscription, currentSubscription) => {
      const prevSubExpiryDate = new Date(prevSubscription.endDate);
      const SubExpiryDate = new Date(currentSubscription.endDate);

      if (prevSubExpiryDate > SubExpiryDate) {
        return prevSubscription;
      }
      return currentSubscription;
    }
  );
  return userSubscriptions;
};

export const hasPremierSubscription = (state) => {
  return (
    getPremierDaysRemaining(state) >= 0 &&
    getLatestUserSubscription(state)?.status?.toLowerCase() !== "cancelled" &&
    getLatestUserSubscription(state)?.status?.toLowerCase() !==
      "purchaseprocessing"
  );
};

export const hasRecurringPremierSubscription = (state) =>
  hasPremierSubscription(state) &&
  getLatestUserSubscription(state)?.instructionType?.toLowerCase() ===
    "recurring";

export const hasOneOffSubscription = (state) =>
  hasPremierSubscription(state) &&
  getLatestUserSubscription(state)?.instructionType?.toLowerCase() === "oneoff";

export const hasTrialPremierSubscription = (state) =>
  hasPremierSubscription(state) &&
  getLatestUserSubscription(state)?.isTrialling === true;

export const getPremierExpiryDate = (state) => {
  const subscription = getLatestUserSubscription(state);
  if (subscription) {
    return applyUtc(subscription.endDate);
  }
  return null;
};

export const getPremierDaysRemaining = (state) => {
  const noDaysLeft = -1;
  const now = new Date();
  const expiry = getPremierExpiryDate(state);

  if (expiry) {
    const daysDifference = differenceInCalendarDays(parseISO(expiry), now);
    return Math.max(noDaysLeft, daysDifference);
  }

  return noDaysLeft;
};

export const getPremierSignUpPending = (state) => {
  return getPremierState(state).premierSignUpPending;
};

export const getPremierIsRedirecting = (state) => {
  return getPremierState(state).redirecting;
};

export const getRenewalDate = (state) => {
  const subscription = getLatestUserSubscription(state);
  if (subscription?.endDate) {
    return new Date(addDays(parseISO(subscription?.endDate), 1));
  }
  return null;
};

export const getRenewalPrice = (state) => {
  const subscription = getLatestUserSubscription(state);
  if (hasOneOffSubscription(state)) {
    return subscription?.prices?.text ?? null;
  } else {
    const price = subscription?.recurringSubscription?.plan?.price;
    if (price) {
      return formatPrice(
        state.application.language,
        price.currency,
        price.value
      );
    }
  }
  return null;
};

export const hasExpiringPremierSubscription = (state) =>
  getLatestUserSubscription(state)?.recurringSubscription
    ?.isMarkedToCancelAtEndOfTerm;
